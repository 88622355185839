
import React, { useEffect, useState } from "react";
import { useThemeContext } from "../../../hooks/usethemeContext";
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Icon } from '@iconify/react';


export default function ThemeSwitch() {

  const [checked, setChecked] = useState(false);
  // const [radioValue, setRadioValue] = useState('1');
  const { darkMode, setDarkMode } = useThemeContext();
  
  document.querySelectorAll('[data-toggle="tooltip"]')
  
  
  useEffect(() => {
    if(darkMode){
      document.querySelector('html').setAttribute("class", "bg-dark");
    } else {
      document.querySelector('html').setAttribute("class", "bg-light");
    };
  }, [darkMode]);
  
    const iconClass = {
        border: darkMode ? 'border-warning': 'border-dark',
        height: '12px',
    };
  
  const iconColor = { color: darkMode ? 'rgb(211, 165, 47)' : 'black' };
  const SunMoon = darkMode ? <Icon style={ iconColor } icon="ph:sun-fill" /> : <Icon style={ iconColor } icon="ri:moon-clear-fill" />;

  const switchTheme = (e) => {
    setChecked(e.currentTarget.checked);
    setDarkMode((prev) => !prev);
  };

  return <>
      <ToggleButton
        id="toggle-check"
        type="checkbox"
        className={`border ${iconClass.border} border-2 rounded`}
        variant={null}
        checked={checked}
        value="1"
        onChange={switchTheme}

        // custom-tooltip
        trigger='hover'
        delay={{"show": 0, "hide": 250}}
        html={'true'}
        data-toggle="tooltip"
        data-placement="auto"
        title="Light/Dark Mode"
      >
        { SunMoon } {/*light / dark theme icons  */}
      </ToggleButton>
  </>;
};
