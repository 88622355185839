import { useEffect, useState } from "react";
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from "../../assets/styles";
import { getTaxSoftwareText, dealType } from "./helpers/taxSoftware";
import { Container, Col, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import PayPal from "./buttons/PayPal";
import CompTable from "./CompTable";


export default function Pricing() {
  const [data, setData] = useState(null);
  const [promo, setPromo] = useState(false);
  const [yrOrDown, setYrOrDown] = useState(false);
  const [promoDate, setPromoDate] = useState('01 Jan 2023')
  const balanceDueDate = data ? data.balance_due_date : '01/01/23';
  // define styles by darkMode
  const { darkMode } = useThemeContext();
  const { dealStyles, pricingBackgroundColor } = switchTheme(darkMode);
  const sm = 1, lg = 3;
  const pricing = promo ? "-promo" : "";
  const yrOrDownText = yrOrDown ? 'yr' : 'yr';

  function checkPromo() {
      const now = new Date();
      const promoEndTime = Date.parse(`${promoDate} 23:59:59 EST`);
      if (promo !== true && promoEndTime > now) {
          setPromo(true);
          setYrOrDown(true);
      }
      else if(promo === true && promoEndTime < now) {
          setPromo(false);
          setYrOrDown(false);
          // setData(null)
          setPromoDate('01 Jan 2023')
      }
  }

  // Fetch data on mount
  useEffect(() => {
    if(data === null) (async function() {
        try {
            const response = await fetch(process.env.REACT_APP_API);
            if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const res = await response.json();
            setData((currentState) => { return currentState = res });
            setPromoDate((currentState) => { return currentState = res.promo_date });
        } catch (error) {
            console.error('Caught error:', error);
        }
    }())
  }); // Empty dependency array means this runs once when the component mounts
  
  useEffect(() => {
    const intervalId = setInterval(checkPromo, 1000 * 1); // checks every second
    return () => clearInterval(intervalId);
  }, [promoDate])

  // Promotions/Deals
  const discount = data ? data.discount : 200;
  const regularPrice = "Regular Price";
  const taxAffiliateRegularPrice = data ? data.first_deal_regular_price  : 599;
  const taxOfficeRegularPrice = data ? data.second_deal_regular_price  : 499;;
  const mentorshipRegularPrice = data ? data.third_deal_regular_price  : 899;
  const isPaymentPlan = data ? data.is_payment_plan  : false;

  const taxAffiliatePromo = taxAffiliateRegularPrice - discount;
  const taxOfficePromo = taxOfficeRegularPrice - discount;
  const mentorshipPromo = mentorshipRegularPrice - discount;
  
  const taxAffiliate = promo ? taxAffiliatePromo : taxAffiliateRegularPrice;
  const taxOffice = promo ? taxOfficePromo : taxOfficeRegularPrice;
  const mentorship = promo ? mentorshipPromo : mentorshipRegularPrice;
  const earlySpecialHTML = <p className="text-muted">Offer Expires {promoDate}</p>
  
  const taxAffiliateBalanceDue = (taxAffiliatePromo * .5).toFixed(2);// asking for half the payment now, half later
  const taxOfficeBalanceDue = (taxOfficePromo * .5).toFixed(2);
  const mentorshipBalanceDue = (mentorshipPromo * .5).toFixed(2);

  const taxSoftwareText = getTaxSoftwareText(
    promo,
    yrOrDown,
    earlySpecialHTML,
    regularPrice,
    balanceDueDate,
    taxOfficeBalanceDue,
    taxAffiliateBalanceDue,
    mentorshipBalanceDue,
    taxOfficeRegularPrice,
    taxAffiliateRegularPrice,
    mentorshipRegularPrice
  );

  return <>
    <div className="pt-4 pb-5">
      <Container>
        <div className="text-center">
          <h2>Free Software Setup</h2>
          <h5>
            Software & Training in English & Spanish Available
          </h5>
          <h5>
            <strong>Free - How To Apply For an EFIN & EIN </strong> - I
            Personally Guide You Through <strong>YOUR </strong> Application
          </h5>
        </div>
        <CompTable />
        <Row
          className={`justify-items-center pricing`} xs={1} sm={sm} lg={lg} > 
          <Col>
            <Card
              className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`} style={dealStyles}
            >
              <Card.Header as={"h4"}>
                {dealType.taxAffiliate}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"}>
                  {taxAffiliate} <small className={darkMode ? "text-muted" : "text-muted"} >/ {yrOrDownText} </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.taxAffiliate}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <PayPal
                    id={'tax-affiliate'}
                    price={taxAffiliate}
                    deal={dealType.taxAffiliate}
                    discount={discount}
                    balanceDue={taxAffiliateBalanceDue}
                    balanceDueDate={balanceDueDate}
                    isPromo={promo}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`} style={dealStyles}
            >
              <Card.Header as={"h4"}>
                {dealType.taxOffice}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"}>
                  {taxOffice} <small className={darkMode ? "text-muted" : "text-muted"} >/ {yrOrDownText} </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.taxOffice}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <PayPal
                    id={'tax-office'}
                    price={taxOffice}
                    deal={dealType.taxOffice}
                    discount={discount}
                    balanceDue={taxOfficeBalanceDue}
                    balanceDueDate={balanceDueDate}
                    isPromo={promo}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`} style={dealStyles}
            >
              <Card.Header as={"h4"}>
                {dealType.mentorship}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"}>
                  {mentorship} <small className={darkMode ? "text-muted" : "text-muted"} >/ {yrOrDownText} </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.mentorship}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <PayPal
                    id={'mentorship'}
                    price={mentorship}
                    deal={dealType.mentorship}
                    discount={discount}
                    balanceDue={mentorshipBalanceDue}
                    balanceDueDate={balanceDueDate}
                    isPromo={promo}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </>;
}
