
import CalendlyInline from "./sub-components/buttons/CalendlyInline";


const Contact = () => {

    return <>
		<div className='justify-items-center'>
			<section id='contact-us-calendly' >
				<CalendlyInline />
			</section>
		</div>
	</>;
}

export default Contact;
