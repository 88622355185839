
import MainBanner from "./sub-components/MainBanner";
import Advantages from './sub-components/Advantages';
import Pricing from "./sub-components/TaxSoftware";


const BusinessOpportunities = () => {


    return <>
        <div>
          <section id='business-opportunities-main-banner' >
            <MainBanner page='software' />
          </section>
          <section id='business-opportunities-advantages' >
            <Advantages page='software' />
          </section>
          <section id='business-opportunities-pricing' >
            <Pricing />
          </section>
        </div>
    </>;
};

export default BusinessOpportunities;