
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from "../../../hooks/usethemeContext";
import { Alert, CloseButton } from 'react-bootstrap';
import { initialOptions } from "./payPalOptions";
import {
  PayPalScriptProvider,
  FUNDING,
  PayPalButtons,
  PayPalMarks,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";


const PayPalInnerComponent = () => {
  const [succeeded, setSucceeded] = useState(false);
  const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [billingDetails, setBillingDetails] = useState("");
  const [orderData, setOderData] = useState({});
  const fundingSources = [FUNDING.CARD, FUNDING.PAYPAL];
  const [selectedFundingSource, setSelectedFundingSource] = useState(fundingSources[0]);
  const [{ isResolved }] = usePayPalScriptReducer();
  const currency = 'USD';
  const errorMessage = `Error: Enter a payment amount greater than $1.00`;
  const navigate = useNavigate();
  const onChange = (e) => setSelectedFundingSource(e.target.value);
  const { darkMode } = useThemeContext();// define styles by darkMode
  const formLabelBackground = darkMode ?  'bg-success' : 'bg-body';
  const PayPalButtonsStyle = { color: darkMode ? 'white' : 'black', };

  // creates a paypal order
  const createOrder = async (data, actions) => {
    try {
      const paymentValue = Number(document.getElementById('tax-fee').value).toFixed(2);
      const dealDescription = document.getElementById('my-option').selectedOptions[0].innerHTML;
      if(paymentValue < 1) throw new Error(errorMessage)

      return await actions.order
        .create({
          purchase_units: [
            {
              description: dealDescription,
              amount: {
                currency_code: currency,
                value: paymentValue,
              },
            },
          ],
          // remove the applicaiton_context object if you need your users to add a shipping address
          // application_context: {
          //   shipping_preference: "NO_SHIPPING",
          // },
        })
        .then((orderID) => {
          setOrderID(orderID);
          return orderID;
        });
      
    } catch (error) {
      alert(error.message);
      window.location.reload();
    }
    
  };

  // handles when a payment is confirmed for paypal
  const onApprove = async (data, actions) => {
    const orderInfo = await actions.order.capture(data.orderID).then( (results) => {return results});

    return await actions.order.capture().then(function (payerData) {
      const { payer } = payerData;
      setOderData(orderInfo);
      setBillingDetails(payer);
      setSucceeded(true);

      // Show a success message within this page, e.g.
      // Or go to another URL:
      navigate('/Success', { state: { orderInfo: orderInfo, deal: document.getElementById('my-option').selectedOptions[0].innerHTML } });

    });
  };
  // handles payment errors
  const onError = async (data, actions) => {
    setPaypalErrorMessage("Something went wrong with your payment");
    alert("Something went wrong with your payment. Press OK to reload this page and try again.")
  };


  return <>
    <div key={19377}>
        <form >
          <div className="mx-5" >
            <p>Select Below:</p>
            <select
              id='my-option'
              className="form-select mb-3 justify-content-center text-center"
              // style={{ color: 'black', width: '50%' }}
              style={{ color: 'black', }}
            >
              <option >Software Payment</option>
              <option>Tax Prep Fee</option>
              <option>Training</option>
              <option>Corporate Tax Consulting</option>
              <option>Crypto Tax Consulting</option>
            </select>

            <label htmlFor={'my-option'}>
              Amount: &nbsp;
            </label>
            <input
              className="mb-3"
              style={{ width: '70%' }}
              id="tax-fee"
              type="number"
              pattern="[0-9]{6}"
              placeholder="Enter Numbers Only"
              name="amount"
              required
            />
            
          </div>
          {fundingSources.map((fundingSource) => (
            <label className={`mark ${formLabelBackground}`} key={fundingSource}>
              <input
                defaultChecked={
                  fundingSource === selectedFundingSource
                }
                onChange={onChange}
                type="radio"
                name="fundingSource"
                value={fundingSource}
              />
              <PayPalMarks fundingSource={fundingSource} className={`${formLabelBackground}`}/>
            </label>
          ))}
        </form>
        <PayPalButtons
          className="mx-5" 
          fundingSource={selectedFundingSource}
          style={PayPalButtonsStyle}
          forceReRender={[darkMode, isResolved]}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
      />
    </div>
  </>
};


const PayPalPay = (props) => {
  return <>
    <PayPalScriptProvider options={initialOptions}>
      <PayPalInnerComponent {...props} />
    </PayPalScriptProvider>
  </>;
};

export default PayPalPay;