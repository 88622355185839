
export const dealType = {
  taxOffice: 'Tax Office',
  taxAffiliate: 'Tax Affiliate',
  mentorship: 'Mentorship',
};

export function getTaxSoftwareText(
  promo,
  yrOrDown,
  earlySpecialHTML,
  regularPrice,
  balanceDueDate,
  taxOfficeBalanceDue,
  taxAffiliateBalanceDue,
  mentorshipBalanceDue,
  taxOfficeRegularPrice,
  taxAffiliateRegularPrice,
  mentorshipRegularPrice
) {

    return {
      taxAffiliate: (
        <>
          {promo ? (
            <>
              {yrOrDown ? (
                <p>{`Balance due ${balanceDueDate} $${taxAffiliateBalanceDue}`}</p>
              ) : (
                <p>{`${regularPrice} $${taxAffiliateRegularPrice}`}</p>
              )}
              {earlySpecialHTML}
            </>
          ) : (
            <></>
          )}
          <p>1 Tax Office User ID</p>
          <p>
            <strong>70/30</strong> Split Prep-Fees
          </p>
          <p>Basic Tax Training</p>
          <p>Mentorship & Marketing Training</p>
          <p>Top Tier Tech Support</p>
        </>
      ),
      taxOffice: (
        <>
          {promo ? (
            <>
              {yrOrDown ? (
                <p>{`Balance due ${balanceDueDate} $${taxOfficeBalanceDue}`}</p>
              ) : (
                <p>{`${regularPrice} $${taxOfficeRegularPrice}`}</p>
              )}
              {earlySpecialHTML}
            </>
          ) : (
            <></>
          )}
          <p>
            <strong>Unlimited</strong> Number Of Users
          </p>
          <p>1 Business Software</p>
          <p>Branded Taxes To Go App</p>
          <p>Mentorship & Marketing Training</p>
          <p>Top Tier Tech Support</p>
        </>
      ),
      
      mentorship: (
        <>
          {promo ? (
            <>
            {yrOrDown ? (
              <p>{`Balance due ${balanceDueDate} $${mentorshipBalanceDue}`}</p>
            ) : (
              <p>{`${regularPrice} $${mentorshipRegularPrice}`}</p>
            )}
            {earlySpecialHTML}
          </>
          ) : (
            <></>
          )}
          <p>Same Options As Tax Office</p>
            {/* <p style={mentorshiptStyle}><strong>+ Added Value:</strong></p> */}
            <p><strong>+ Added Value:</strong></p>
            <p><strong>Personal</strong> Mentorship Sessions</p>
            <p><strong>Advanced</strong> Marketing</p>
            <p ><strong>Advanced</strong> Sales</p>
        </>
      ),
    };
}