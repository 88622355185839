
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from "../../assets/styles";
import Carousel from 'react-bootstrap/Carousel';

import pic1 from '../../assets/images/banner-1.webp';
import pic2 from '../../assets/images/taxes-togo-banner-1.webp';
import pic3 from '../../assets/images/taxes-togo-banner-2.webp';
import pic4 from '../../assets/images/taxes-togo-banner-3.webp';
import pic5 from '../../assets/images/taxes-togo-banner-4.webp';


export default function ReactCarousel(props) {
  const { darkMode } = useThemeContext();
  const { mainBannerTextColor, backgroundColor } = switchTheme(darkMode);

  const intervalTime = 5000, corners = 3;
  const styles = {
    margin: 'auto',
    width: '60%',
  };
  const opacity = {opacity:'0.5'}
  const padding = { marginBottom:'50px' };


  return (
    <>
      <Carousel>
      {/* <Carousel fade controls={false} indicators={false}> */}
        <Carousel.Item interval={intervalTime}>
          <img
            className={`mb rounded-${corners} d-block`}
            // className={`rounded-${corners} d-block ${padding}`}
            style={styles}
            src={pic1}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className={`${mainBannerTextColor} ${opacity} carousell`}>
              <p>
                Software is <strong>NOT </strong>the business, People are <strong>OUR </strong> business!
              </p>
            </div>
          </Carousel.Caption>
          <br /> <br /> <br /> <br /> <br />
        </Carousel.Item>
        <Carousel.Item interval={intervalTime}>
          <img
            className={`mb rounded-${corners} d-block`}
            // className={`rounded-${corners} d-block ${padding}`}
            style={styles}
            src={pic2}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className={`${mainBannerTextColor} ${opacity} carousell`}>
              <p>
                FREE Taxes-To-Go App, Upload Documents, Sign, & Send
              </p>
            </div>
          </Carousel.Caption>
          <br /> <br /> <br /> <br /> <br />
        </Carousel.Item>
        <Carousel.Item interval={intervalTime}>
          <img
            className={`mb rounded-${corners} d-block`}
            // className={`rounded-${corners} d-block ${padding}`}
            style={styles}
            src={pic3}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className={`${mainBannerTextColor} ${opacity} carousell`}>
              <p>
                Stay On Top Of Your Business With Alerts & Reminders
              </p>
            </div>
          </Carousel.Caption>
          <br /> <br /> <br /> <br /> <br />
        </Carousel.Item>
        <Carousel.Item interval={intervalTime}>
          <img
            className={`mb rounded-${corners} d-block`}
            // className={`rounded-${corners} d-block ${padding}`}
            style={styles}
            src={pic4}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className={`${mainBannerTextColor} ${opacity} carousell`}>
              <p>
                Simply Follow The Steps & Prompts
              </p>
            </div>
          </Carousel.Caption>
          <br /> <br /> <br /> <br /> <br />
        </Carousel.Item>
        <Carousel.Item interval={intervalTime}>
          <img
            className={`mb rounded-${corners} d-block`}
            // className={`rounded-${corners} d-block ${padding}`}
            style={styles}
            src={pic5}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className={`${mainBannerTextColor} ${opacity} carousell`}>
              <p>
                Chat With Your Clients In A Safe & Secured App
              </p>
            </div>
          </Carousel.Caption>
          <br /> <br /> <br /> <br /> <br />
        </Carousel.Item>
      </Carousel>
    </>
  );
}