
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { useThemeContext } from "../hooks/usethemeContext";
import { switchTheme } from '../assets/styles';

const Success = () => {

    // define styles by darkMode
    const { darkMode } = useThemeContext();
    const { bg, text, uploadButtonBG } = switchTheme(darkMode);

    const navigate = useNavigate();
    const location = useLocation();

    return <>
        <div id="contact" className="pt-5 mt-5 mb-5">
            <Container className='text-center'>
            {
                location.state !== null ?
                <div>
                    <h5>Thank you for your payment!</h5>

                    <Container className={`justify-items-center card mt-5 bg-${bg} ${text} border border-primary`} style={{width: '25rem'}}>
                        <div className={`card-body text-start bg-${bg} ${text}`}>
                            <h5 className="card-title text-center">Purchase Details</h5>
                            <p className={`card-text ${text}`}><strong>Name: </strong>{location.state.orderInfo.payer.name.given_name} {location.state.orderInfo.payer.name.surname}</p>
                            <p><strong>Email: </strong>{location.state.orderInfo.payer.email_address}</p>
                            <p><strong>Order: {location.state.deal}</strong></p>
                            <p><strong>Amount: ${location.state.orderInfo.purchase_units[0].amount.value}</strong></p>
                            {
                                location.state.promo && location.state.price.toFixed(2) !== location.state.orderInfo.purchase_units[0].amount.value ? <p><strong>Balance Due on {location.state.balanceDueDate} - ${location.state.balanceDue}</strong></p> : <></>
                            }
                            <p><strong>PayPal Order ID: </strong>{location.state.orderInfo.id}</p>
                        </div>
                    </Container>
                    
                    <h5 className="mt-5"> Use our Secure Form! </h5>
                    <h5>
                        Upload the documents for the {location.state.deal} Package.
                    </h5>
                        <a 
                            className={`mt-3 btn btn-outline-${uploadButtonBG}`}
                            href={process.env.REACT_APP_FORM_URL}
                            target="_blank"
                            rel='noreferrer'
                        > 
                            Upload Your Documents
                        </a>
                    <p className="mt-3">You can save your progress and finish later.</p>
                </div> :
                <div style={{ display: 'block', width: '400px', paddingTop: '200px', paddingBottom: '200px'}} className="text-center mx-auto" onLoad={setTimeout(() => {navigate('/')}, 3000)} >
                    <div>
                        <h5>You don't have any current purchases</h5>
                        <h5>Goint to homepage</h5>
                    </div>
                </div>
            }
        </Container>
    </div>
    </>;
}
 
export default Success;