
import { useThemeContext } from "../hooks/usethemeContext";
import { switchTheme } from '../assets/styles';
import { Container, Row } from "react-bootstrap";
import { Card } from 'react-bootstrap';
import PayPalPay from './sub-components/buttons/PayPalPay';


export default function MakePayment() {
    
    // define styles by darkMode
    const { darkMode } = useThemeContext();
    const { dealStyles, pricingBackgroundColor } = switchTheme(darkMode);

    const xs=1, sm=2, md=2, lg=12;


    return <>
        <div className='pt-5 pb-5' style={{ minHeight: "70vh", marginTop: '70px' }}>
            <Container>
                <div className="text-center">
                    <h2>Make Easy Payments</h2>
                </div>
                <Row className={`justify-items-center pricing`} xs={xs} sm={sm} md={md} lg={lg}>
                    <Container>
                        <Card className={`bootstrap-card shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`} style={dealStyles}>
                            <Card.Header as={'h4'}>
                            {/* <Card.Header as={'h4'} style={dealStyles}> */}
                                Pay with PayPal
                            </Card.Header>
                            <Card.Body>
                                {/* <Card.Title as={'h1'}>
                                    {taxOffice} <small className="text-muted">/ yr</small>
                                </Card.Title> */}
                                <Card.Text as={'h5'}>
                                    <ul className="list-unstyled mt-3">
                                        <p>Software Payment</p>
                                        <p>Tax Prep Fee</p>
                                        <p>Training</p>
                                        <p>Corporate Tax Consulting</p>
                                        <p>Crypto Tax Consulting</p>
                                    </ul>
                                </Card.Text>
                                <div className="d-grid position-sticky top-100">
                                    <PayPalPay id={'make-payment'}/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Container>
                </Row>
            </Container>
        </div>
  </>;
}
