
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { advantagesText } from "./helpers/advantagesText";
import CalendlyButton from "./buttons/CalendlyButton";
import { Col, Container, Row } from "react-bootstrap";
import FunFacts from "./FunFacts";


const Advantages = (props) => {

	// define style by darkMode
    const { darkMode } = useThemeContext();
    const { backgroundColor, borderTop, advantagesIconColor } = switchTheme(darkMode);

	const sm = 6, lg = 6;
	let altText = advantagesText.taxClient;
	if(props.page === 'software') altText = advantagesText.taxPartner;

    return <>
		<div id="services" className={`pt-5 pb-5`} style={borderTop} >
			<Container className="pt-5 pb-3">
				{ altText.heading1 }
			</Container>
			<div className='mb-5 pb-5'>
				<FunFacts />
			</div>
			<Container>
				<div className="justify-items-center">
					<Row className="align-items-center pb-">
						<Col lg={lg}>
							<Row>
								<Col lg={lg} sm={sm}>
									<div className={`single-services-box ${backgroundColor}`}>
										<i className="bi bi-database-lock" style={advantagesIconColor}></i>
										{ altText.box1 }
									</div>
								</Col>

								<Col lg={lg} sm={sm}>
									<div className={`single-services-box ${backgroundColor}`}>
										<i className="bi bi-pencil-square" style={advantagesIconColor}></i>
										{ altText.box2 }
									</div>
								</Col>
							</Row>
						</Col>

						<Col lg={lg}>
						<Row>
								<Col lg={lg} sm={sm}>
									<div className={`single-services-box ${backgroundColor}`}>
										<i className="bi bi-cloud-upload" style={advantagesIconColor}></i>
										{ altText.box3 }
									</div>
								</Col>

								<Col lg={lg} sm={sm}>
									<div className={`single-services-box ${backgroundColor}`}>
										<i className="bi bi-people" style={advantagesIconColor}></i>
										{ altText.box4 }
									</div>
								</Col>
							</Row>
						</Col>
						{
							props.page === 'clients' ?
							<>
								<Col lg={lg}>
								<Row>
										<Col lg={lg} sm={sm}>
											<div className={`single-services-box ${backgroundColor}`}>
												<i className="bi bi-code-square" style={advantagesIconColor}></i>
												{ altText.box5 }
											</div>
										</Col>

										<Col lg={lg} sm={sm}>
											<div className={`single-services-box ${backgroundColor}`}>
												<i className="bi bi-bank2" style={advantagesIconColor}></i>
												{ altText.box6 }
											</div>
										</Col>
									</Row>
								</Col>
								<div className="col-lg-6">
									<Row>
										<Col lg={lg} sm={sm}>
											<div className={`single-services-box ${backgroundColor}`}>
												<i className="bi bi-cash-coin" style={advantagesIconColor}></i>
												{ altText.box7 }
											</div>
										</Col>

										<Col lg={lg} sm={sm}>
											<div className={`single-services-box ${backgroundColor}`}>
												<i className="bi bi-calendar2-day" style={advantagesIconColor}></i>
												{ altText.box8 }
											</div>
										</Col>
									</Row>
								</div>

								{ 
									props.page === 'clients' ? 
										<div className="d-flex justify-content-center mt-5">
											<br />
											<CalendlyButton />
										</div> 
									: <></>
								}
							</>
							:
							<></>
						}
					</Row>
				</div>
			</Container>
		</div>
	</>;
}

export default Advantages;