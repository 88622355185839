
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from "../../../hooks/usethemeContext";
import { initialOptions } from "./payPalOptions";
import {
  PayPalScriptProvider,
  FUNDING,
  PayPalButtons,
  PayPalMarks,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";


const PayPalInnerComponent = (props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [billingDetails, setBillingDetails] = useState("");
  const [orderData, setOrderData] = useState({});
  const fundingSources = [FUNDING.CARD, FUNDING.PAYPAL];
  const [selectedFundingSource, setSelectedFundingSource] = useState(fundingSources[0]);
  const [{ isResolved }] = usePayPalScriptReducer();
  const currency = 'USD';
  const navigate = useNavigate();
  const onChange = (e) => setSelectedFundingSource(e.target.value);
  // define styles by darkMode
  const { darkMode } = useThemeContext();
  const PayPalButtonsStyle = { color: darkMode ? 'white' : 'black', };
  const formLabelBackground = darkMode ?  'bg-success' : 'bg-body';

  // creates a paypal order
  const createOrder = async (data, actions) => {
    return await actions.order
      .create({
        purchase_units: [
          {
            description: `Professional ${props.deal} Software Package`,
            amount: {
              currency_code: currency,
              value: document.getElementById(`item-options-${props.id}`).value,
            },
          },
        ],
        // remove the applicaiton_context object if you need your users to add a shipping address
        // application_context: {
        //   shipping_preference: "NO_SHIPPING",
        // },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // handles when a payment is confirmed for paypal
  const onApprove = async (data, actions) => {
    const orderInfo = await actions.order.capture(data.orderID).then( (results) => {return results});

    return await actions.order.capture().then(function (payerData) {
      const { payer } = payerData;
      setOrderData(orderInfo);
      setBillingDetails(payer);
      setSucceeded(true);

      // Show a success message within this page, e.g.
      // Or go to another URL:
      navigate('/Success', { state: { orderInfo: orderInfo, price: props.price, promo: props.isPromo, deal: props.deal, balanceDue: props.balanceDue? props.balanceDue : undefined , balanceDueDate: props.balanceDueDate? props.balanceDueDate : undefined }});

    });
  };
  // handles payment errors
  const onError = async (data, actions) => {
    setPaypalErrorMessage("Something went wrong with your payment");
    alert("Something went wrong with your payment. Press OK to reload this page and try again.")
  };


  return <>
    <div key={props.id}>
        <form style={{ minHeight: "100px" }}>
          <div>
            {
              props.deal !== 'mentorship' ?
                <div>
                  <select name="on0" id={`item-options-${props.id}`}>{/* tax-office */}
                    {props.isPromo ?
                      <option
                        name={props.deal}
                        value={props.balanceDue}
                      >
                        Pay ${props.balanceDue} Now - Balance {props.balanceDueDate}
                      </option>
                      :
                      <option
                        name={props.deal}
                        value={props.price}
                      >
                        Pay ${props.price}
                      </option>
                    }

                    {props.isPromo ? 
                      <option
                        name={props.deal}
                        value={props.price}
                      >
                        Pay ${props.price} Now - $0 Balance
                      </option>
                      :
                      null
                    }
                  </select>
                </div>
                :
                <div>
                  <select name="on0" id={`item-options-${props.id}`}>
                    {props.isPromo ?
                      <option
                        name={props.deal}
                        value={props.price}
                      >
                        Pay ${props.price} Now - $0 Balance
                      </option>
                      :
                      <option
                        name={props.deal}
                        value={props.price}
                      >Pay ${props.price}</option>
                    }
                  </select>
                </div>
            }
          </div>
          {fundingSources.map((fundingSource) => (
            <label className={`mark ${formLabelBackground}`} key={fundingSource}>
              <input
                defaultChecked = {
                  fundingSource === selectedFundingSource
                }
                onChange={onChange}
                type="radio"
                name="fundingSource"
                value={fundingSource}
              />
              <PayPalMarks fundingSource={fundingSource} className={`${formLabelBackground}`}/>
            </label>
          ))}
        </form>
        <PayPalButtons
          className="mx-5" 
          fundingSource={selectedFundingSource}
          style={PayPalButtonsStyle}
          forceReRender={[darkMode, isResolved]}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
      />
    </div>
  </>
};


const PayPal = (props) => {
  return <>
    <PayPalScriptProvider options={initialOptions}>
      <PayPalInnerComponent {...props} />
    </PayPalScriptProvider>
  </>;
};

export default PayPal;