
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import about1 from '../../assets/images/about-1.webp';
// import about2 from '../../assets/images/about-2.webp';


const AboutUs = () => {
	// define style by darkMode
	const { darkMode } = useThemeContext();
	const { mainBannerTextColor, backgroundColor } = switchTheme(darkMode);

	const style = {color: '#0e65d7'};
	const sm = '12', lg = '6'; // setting for 2 people about page
	const styles = { margin:  "auto"};
	const space = 3;

    return (
      <>
        <div className={`about-area mt-5 pb-${space}`}>
			<div className={`text-center pb-${space} border-bottom-0`}>
				<h1 className={mainBannerTextColor}>
					Who We Are!
				</h1>
			</div>
			<Container className="justify-items-center justify">
				<Row className="justify-items-center">
					<Col> {/* setting for 1 person about page */}
					{/* <Col sm={sm} lg={lg}> */} {/* setting for 2 people about page */}
						<Row>
							<div className={`pt-${space}`}>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about1}
									alt="First slide"
								/>
								<h4 className={`text-center ${mainBannerTextColor}`}>
									Patrice Kabanga
								</h4>
								<p>
									Hi my name is Patrice Kabanga and I'm happily married to my beautiful wife, Florence. I'm a father of two children and I was born and raised in Kinshasa in the Democratic Republic of the Congo, Central Africa. We immigrated to the United States in 2008 due to lack of opportunities at the time. The level of unfairness and legal corruption prevents a fair treatment for the average person. So, I left for the U.S. because I believe that this is the land of opportunity for everyone. Opportunity to provide a better future for my family and opportunity to have financial freedom. We love to travel and visit this beautiful country. We go away for a few days or weeks and come back fresh and ready to take on challenges of life head on.
								</p>
								<p>
									Trying to understand the tax laws and dealing with my own tax situation was a big challenge to me. So I made the decision to overcome that situation and learn about taxes. I earned a Master in Business Administration from the University of Phoenix in 2012. But I also took tax related master's level courses and this is truly what prepared me to open a Tax Preparation Business. That same year I prepared and filed the income tax return for my family.
								</p>
								<p>
									In 2014 I officially opened a business and started filing income tax returns for individuals and businesses across the United States. I currently manage over 300 personal and business income tax returns and helping other tax preparers learn and be successful.
								</p>
								<p>
									I truly enjoy working with people and helping them realize their financial goals through a simple and tailored Tax Strategy.  I want help you realize your dreams and help you get there.  If you're looking for a business opportunity, I'd love to help you achieve that also.  My goal is to build a team of highly qualified and trained tax professionals dedicated to helping people, their families, and their business reach financial freedom.
								</p>
							</div>
						</Row>
					</Col>
					{/* <Col sm={sm} lg={lg}>
						<Row>
									<div className={`pt-${space}`}>
									<h4 className={`text-center ${mainBannerTextColor}`}>
										Jose
									</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about2}
									alt="First slide"
								/>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
							</div>
						</Row>
					</Col> */}
						<Container className="text-center">
							{/* <h5 className={`text-center ${mainBannerTextColor}`}>
								Our Commitment to You!
							</h5> */}
							{/* <p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p> */}
							<h5 className={`text-center ${mainBannerTextColor}`}>
								My Commitment to You!
							</h5>
							<p>
								My goal is to change people's lives by providing the best business services and opportunities. My Team and I are committed to providing the best tax preparation, accounting and bookkeeping services for our clients. We believe everyone deserves to move their financial life forward. We've offered the knowledge and expertise to help make that possible for more than 10 years now, and we will continue to help you with your income tax and bookkeeping needs.
							</p>
					</Container>
					<NavLink
						to="/contact"
						className={`mt-${space} btn btn-outline-primary`}
					>
						Contact us
					</NavLink>
				</Row>
          	</Container>
        </div>
      </>
    );
};

export default AboutUs;
